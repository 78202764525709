<template>
	
<div class="search">

	<app-icon s="close" class="search-close" v-on:click.native="$emit('close')" />

	<div class="search-inner">

		<div class="search-box">

			<input type="text" v-model="search" placeholder="Type to search..." class="search-box-input" />

			<app-icon s="search" class="search-box-icon" />

		</div>

		<div class="search-results">

			<div class="search-results-item" v-for="(item, index) in results" :key="index">

				<div class="search-results-item-name"><a :href="item.url">{{ item.name }}</a><span>{{ item.type }}</span></div>
				<div class="search-results-item-description">{{ item.description }}</div>

			</div>

		</div>

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			search: '',
			results: []
		}

	},

	watch: {

		search: function() {

			this.runSearch()

		}

	},

	methods: {

		runSearch: function() {

			if (this.search.length < 2) {

				this.results = []

			} else {

				this.$api.cancel('search')

				this.$api.get('search', {
					text: this.search,
					region: this.$store.getters['language'].id,
					context: this.$store.getters['context'].id,
					all: true
				}).then(function(json) {

					this.results = json.results

				}.bind(this))

			}

		}

	}

}

</script>

<style scoped>

.search {
	position: fixed;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	background-color: rgba(26, 35, 76, 0.95);
	z-index: 1000;
}

.search-close {
	width: 52px;
	height: 52px;
	line-height: 54px;
	text-align: center;
	border-radius: 26px;
	background-color: rgba(20, 146, 255, 1);
	color: #fff;
	position: absolute;
	top: 26px;
	right: 26px;
	font-size: 32px;
	cursor: pointer;
	z-index: 2;
}

.is-device .search-close {
	top: 16px;
	right: 26px;
}

.search-inner {
	padding: 155px 20px 20px 20px;
	max-width: 1120px;
	margin: 0px auto;
}

.is-device .search-inner {
	padding: 90px 20px 20px 20px;
}

.search-box {
	width: 100%;
	max-width: 660px;
	margin: 0px auto;
	border-radius: 32px;
	height: 64px;
	background-color: #fff;
	display: flex;
}

.search-box-input {
	height: 64px;
	padding: 0px 30px;
	width: calc(100% - 58px);
	font-size: 20px;
	color: #1a234c;
	font-weight: 400;
}

.search-box-icon {
	width: 52px;
	height: 52px;
	border-radius: 26px;
	background-color: rgba(20, 146, 255, 1);
	color: #fff;
	line-height: 52px;
	font-size: 32px;
	margin-top: 6px;
	text-align: center;
}

.search-results {
	margin-top: 64px;
}

.is-device .search-results {
	margin-top: 40px;
}

.search-results-item {
	margin-bottom: 20px;
}

.search-results-item-name {
	color: #fff;
	font-weight: bold;
	font-size: 24px;
	margin-bottom: 20px;
}

.is-device .search-results-item-name {
	font-size: 20px;
}

.search-results-item-name:hover {
	text-decoration: underline;
}

.search-results-item-name span {
	display: inline-block;
	background-color: rgba(20, 146, 255, 1);
	border-radius: 16px;
	font-size: 12px;
	padding: 4px 10px;
	margin-left: 20px;
}

.search-results-item-name:hover span {
	text-decoration: none;
}

.search-results-item-description {
	color: #fff;
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
}

.is-device .search-results-item-description {
	font-size: 14px;
}

</style>
